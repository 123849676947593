.sidebar {margin-top: 2em;}

.sidebar .widget {margin-bottom: 3em;}
.widget h3 {font-size: 1.25em;color: $gray-light;}

.widget ul {
  list-style: none;
  padding: 0;
}

.widget li {
  border-bottom: 2px solid #eee;
  margin: 0em auto;
  padding: .25em 1em .25em 1.3em;
}

.widget li:before {
  content: "\f054"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.5em; /* same as padding-left set on li */
  width: 1.5em; /* same as padding-left set on li */
  opacity: 1;
  color: #ccc;
  font-size: .75em;
}


@media (max-width: $screen-md-min) {
.site-search span {display:none;}
}