/*
.navbar {margin-bottom: 0px;}
.navbar-inverse {background-color: #333333;}
.navbar-brand {height: auto;padding: 5px;} 
.home .navbar {margin-bottom: 0px;background-color: transparent;}
.navbar-inverse {border:0;}
.navbar-inverse .navbar-nav>li.login>a {color: lighten($brand-primary, 25%);}
*/

/*
.navbar-inverse .navbar-nav>li>a {border: 2px solid fade($gray-dark,.95) !important;background: fade($gray-dark,.85) !important; margin-left:.5em;padding: .35em .5em;}

.navbar-inverse .navbar-nav>li>a {border: 2px solid opacify(darken($brand-primary,20%),.85) !important;background: opacify(darken($brand-primary,20%),.65) !important; margin-left:1em;margin-top:1.25em; padding-top: .35em; padding-bottom: .35em;
}
*/

html{
    height: 100%;
    margin: 0;
    padding: 0;
}

#primaryContent {
    background-color: white;
    position: relative;
    margin-top: 0em;
}

.masthead header {
	margin-bottom: 1em;
}
.navbar {
	margin-bottom: 0em;
}




.content-info {
margin-top:0em;
padding-top:1em;
background: #23282d;
color:#ccc;
opacity:1;
border-top: none;
}

h1 {font-size: 2.5em;font-weight: normal;}
h2 {font-size: 1.75em;font-weight: normal;}
h3 {font-size: 1.25em;font-weight: normal;}
p {font-size: 1em;}

.boxText h1, .boxText h3, .boxText h3,
.block h1, .block h3, .block h3
{color:white;margin-top: 0em;}

.block h1, .block h3, .block h3 {
	margin: 0em auto;
}


.awards img {
max-width: 75%;
height: auto;
}

.anchor {
position: relative;
top: -100px;
margin-top: 100px;
visibility: hidden;
}

.vc_grid .vc_gitem-col .vc_gitem-link {
border-bottom: 2px solid #FF675B; /* Need to override something that's erasing borders */
}

.vc_tta-style-flat .vc_tta-tab.vc_active > a {
	background: $brand-primary !important;
	color: white !important;
}

/* 
h1, h2, h3, h4 {color: $brand-primary;}
 */
 
@media (max-width: $screen-sm-min) {
	.center-xs, .vc_btn3-container.vc_btn3-left {text-align:center;} 
	h1 br, h2 br, h3 br {display:none;}
}

.wrap {
    width: 100% !important;
}


.help-footer {
	background: #333;
}
.help-footer h3 {
	margin:0em auto .5em;
	font-size: 2em;
	color:white;
	text-align:center;
	width:100%;
	text-transform: uppercase;
}

.help-footer .vc_sep_line {
border-color: #000;
}

.help-footer .or {
	width: 100%;
	text-align:center;
	color:#aaa;
	display:inline-block;
}



.help-footer .gform_wrapper {
	margin-top:0px;
}

.gform_wrapper input[type="text"], 
.gform_wrapper input[type="email"], 
.gform_wrapper textarea {
	font-size:1em !important;
	color: #ccc;
	background-color: #eee;
	border: 2px solid #ccc;
	outline: none;
	font-style:normal;
	text-transform:uppercase;
	line-height: 1.2em;
	padding: .75em .35em;
	margin-bottom:0px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.cta-sidebar > * > .wpb_wrapper {
/* 	margin:1em auto; */
	padding:1em;
	background-color:fade-out($brand-primary,.75);
	border: 4px solid fade-out($brand-primary,.5);
}
.cta-sidebar .wpb_wrapper .wpb_content_element {
	margin-bottom: 0em;
}


.cta-sidebar .gform_wrapper h3.gform_title {
	font-size: 1.25em;
	font-weight: normal;
}
.cta-sidebar .gform_description {
	font-size:.85em !important;
	margin-bottom: 1.75em;
}


.cta-sidebar #field_2_6 > label {
	display:none;
}
.cta-sidebar #field_2_6 .ginput_container_checkbox {
	margin-top: 0em;
}

.cta-sidebar #field_2_6 #label_2_6_1 {
	line-height: 1.35em;
	font-weight:normal;
	font-size: .85em;
	font-style: italic;
	padding-left: .5em;
	margin-top: .5em;
	opacity: .75;
}


.cta-usecase {
	background-color:#eeeeee;
}

.help-footer .gform_wrapper input[type="text"], 
.help-footer .gform_wrapper input[type="email"], 
.help-footer .gform_wrapper textarea {
	font-size:1.35em !important;
	color: #ddd;
	background-color: #444;
	border: 2px solid #444;
}

.footnote {
font-size: .8em;
opacity: .75;
line-height: 1.15em;
}

/*
#field_3_9, #field_3_2, #field_3_6 {float:left;clear:none;display:inline;width: 100%;}
*/



.expectations {
	color:white;
	border:dashed 2px white;
	padding: 1em;
}

.expectations h4 {
	border-bottom:dotted white 1px;
	text-transform:uppercase;
	padding-bottom:.25em;
	color:white;
}

.expectations ul {
	list-style:none;
	padding-left:0em;
}
.expectations ul li {
	padding: 0em;
}

#pageGrid .desc {
	font-size: .9em;
	line-height: 1.15em;
}


.useCaseFeatures h3 {
	margin-top: .35em;
	font-size: 1.25em;
}
.useCaseFeatures p {
	font-size: .85em;
}


#featureGrid .feature {
	border: 1px solid #ccc;
	padding: 2em 0em 0em;
	margin: 0em auto 2em;
}


.useCaseFeatures .vc_icon_element,
#featureGrid .vc_icon_element {
	margin-bottom: .75em;
}

#featureGrid .subheader {
	margin-bottom: .25em;
}
#featureGrid .subheader h3 {
	margin-top: .35em;
	font-size: 1.25em;
}

#featureGrid .desc {
	margin-bottom: 2em;
	font-size: .825em;
}

.useCaseFeatures .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-outline,
#featureGrid .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-outline
{
	border-color: fade-out($brand-primary,.35);
	border-width: 4px;
}

.useCaseFeatures .vc_icon_element-icon.fa,
#featureGrid .vc_icon_element-icon
{
	color: fade-out($brand-primary,.15) !important;
}

.floating-text, .masthead {
	min-height: 350px;
}

.boxText,
.floating-text .wpb_text_column {
	background: fade-out($brand-primary,.25);
	display:inline-block;
	padding: 1em 1.5em;
	color:white;
	text-align: left;
}
.block {
	background: fade-out($brand-primary,.25);
	display:block;
	padding: 1em 1.5em;
	color:white;
}
.floating-text .wpb_text_column {
	font-size: 1.35em;
	line-height:1.25em;
}
.floating-text .wpb_text_column strong {
	font-weight: normal;
	font-size: 1.15em;
}

.align-center {
	text-align: center;
}
.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}

/* 
.main {padding-left:7.5px;padding-right:7.5px;}
 */

/* 

.vc_column_container {padding-left:15px;padding-right:15px;}
.vc_row {margin-left:-15px;margin-right:-15px;}

*/

