// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.ginput_container_select {

}

.hide-label .gfield_label {display:none !important;}
.ginput_container_select {}
.gfield_select {
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

  // [converter] extracted textarea& to textarea.form-control
}

.ginput_container input, .ginput_container textarea {

  border: 1px solid $input-border;
  border-radius: 5px;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
  color: $input-color;

}

.gform_footer {text-align:right;}
.gform_button {
	@extend .btn-danger;
	text-transform: uppercase;
	border: none;
	padding: .5em;
}
.um-form .um-button {
}


.wrap .um-um_account_id.um input[type=submit].um-button, .wrap .um-um_account_id.um input[type=submit].um-button:focus, .wrap .um-um_account_id.um a.um-button, .wrap .um-um_account_id.um a.um-button.um-disabled:hover, .wrap .um-um_account_id.um a.um-button.um-disabled:focus, .wrap .um-um_account_id.um a.um-button.um-disabled:active {
@extend .btn-danger;
}


/*
.btn-primary, .comment-form input[type=submit], .gform_button, .wrap .um-um_account_id.um a.um-button, .wrap .um-um_account_id.um a.um-button.um-disabled:active, .wrap .um-um_account_id.um a.um-button.um-disabled:focus, .wrap .um-um_account_id.um a.um-button.um-disabled:hover, .wrap .um-um_account_id.um input[type=submit].um-button, .wrap .um-um_account_id.um input[type=submit].um-button:focus {
	background:red;
	text-transform: uppercase;
	border: none;
}
*/

.gforms_confirmation_message {
	color: #b0d69c;
	font-size: 1.5em;
}

.gform_wrapper div.validation_error {
	border: none;
	color: white;
	background: red;
	padding: 1em;
}

.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
	background-color: #ddc7c7;
	border: none;
}