.blog .page-header h1,
.search .page-header h1,
.archive .page-header h1
{font-size: 135%;}
.blog .page-header,
.search .page-header,
.archive .page-header
{margin-bottom: 1em;}
.blog article,
.search article,
.archive article
{
background-color:fade-out(#eee, .5);
border-top: 6px solid fade-out(#eee, .25);
margin-bottom: 3em;
padding: .75em 1.5em 2em;
}
.blog article h2,
.search article h2,
.archive article h2 {font-size: 1.5em;}
.blog article h2 a,
.search article h2 a,
.archive article h2 a {font-weight:normal;}
.blog article h2 a:hover,
.search article h2 a:hover,
.archive article h2 a:hover {text-decoration:none;}

.blog article header,
.search article header,
.archive article header {margin-bottom: .5em;display:inline-block;clear:both;width: 100%;color:#999;}
.blog article header time,
.search article header time,
.archive article header time {float:left;clear:none;display:inline-block;font-size: 85%;}
.blog article header .byline,
.search article header .byline,
.archive article header .byline {float:right;clear:none;display:inline-block;font-size: 85%;}
.blog article .entry-summary,
.search article .entry-summary,
.archive article .entry-summary {line-height: 1.25em;}


.single article header {margin: 1em auto 1.5em;display:inline-block;clear:both;width: 100%;border-bottom: 1px solid #eee;}
.single article header time {float:left;clear:none;display:inline-block;font-size: 85%;}
.single article header .byline {float:right;clear:none;display:inline-block;font-size: 85%;}

