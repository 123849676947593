.content-info {
	border-top: 2px solid #eee;
	margin-top: 1em;
	padding: 1em;
}

.content-info {
	font-size: .8em;
	opacity: .5;
}

footer .legal {
	text-align:center;
	margin: 2em auto;
}


.soc-footer {text-align:right;}
.soc-footer li {display:inline-block;font-size: 1.75em;}
.soc-footer li:before {content: '' !important;}
