#topBar {
	padding: .5em;
	background-color: $gray-darker;
	color: white;
}

.navbar-toggle {
padding: .25em .6em;
font-size: 1.25em;
color: darken($navbar-default-toggle-border-color, 5%);
}

#navbar-main {
	width: 100%;
	z-index: 9998;
}

#navbar-main.affix {
	top: 0;
	margin-top: 0px !important;
	width: 100%;
}

#navbar-main a.custom-logo-link {margin: 10px;cursor:pointer;display:inline-block;}
#navbar-main a.custom-logo-link img {
	opacity: 1;
	width: auto;
	max-height: 60px;
}

#navbar-main.affix a.custom-logo-link {margin: 5px 10px;}
#navbar-main.affix a.custom-logo-link img {
	opacity: .75;
	max-height: 40px;
}


#navbar-main ul#menu-primary li, 
#navbar-main ul#menu-primary a
{
	display:table-cell;
	vertical-align:middle;
}
#navbar-main ul#menu-primary a {padding: .5em .75em;height: 100%;}

#navbar-main.affixX,
#navbar-main.affix ul#menu-primary li, 
#navbar-main.affix ul#menu-primary a
{
	height: 50px !important;
}


#navbar-main.loading *
{
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -o-transition: color 0 ease-in !important;
    transition: none !important;

}

#navbar-main ul#menu-primary li, #navbar-main.affix ul#menu-primary li, 
#navbar-main ul#menu-primary a, #navbar-main.affix ul#menu-primary a,
#navbar-main, #navbar-main.affix, #primaryContent, #stickyFill
{
    -webkit-transition: height 0s; /* For Safari 3.1 to 6.0 */
    transition: height 0s;

    transition-timing-function: ease;

	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);

}

#navbar-main.affix a.custom-logo-link img, #navbar-main a.custom-logo-link img,
ul#menu-primary li, ul#menu-primary a
{
    -webkit-transition: all .15s; /* For Safari 3.1 to 6.0 */
    transition: all .15s;

	transition-timing-function: ease;

	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);

}

#navbar-main.affix + #primaryContent {
  padding-top: 0px;
}


#stickyFill { height: 0px; display:block}


 
#navbar-main ul#menu-primary .dropdown-menu li, 
#navbar-main ul#menu-primary .dropdown-menu a {
	display:inline-block;
	width: 100%;
	height: auto !important;
}


#topBlock {position:absolute;top:0px;left:0px;width:100%;z-index:-999;}


/* 
.page-header {padding: 0em 1em;}
 */


@media (max-width: $screen-sm-min) {
/* 
	.menu-item:hover > ul.dropdown-menu, .dropdown-toggle .caret {
		display:none;
	}
 */
    .affix {
        position: static;
    }

	.navbar-nav {margin-left: 0px;margin-right: 0px;}
	.navbar-collapse {padding-left: 0px;padding-right: 0px;}
	.navbar-nav .open .dropdown-menu {margin-left:10px;font-size: .85em;}

	#navbar-main a.custom-logo-link {margin: 5px 10px;}
	#navbar-main a.custom-logo-link img {
		opacity: .75;
		max-height: 40px;
	}

	#navbar-main {margin-top: 0px !important;}

	#navbar-main ul#menu-primary {background: fade-out($brand-primary, .85);}
	#navbar-main ul#menu-primary li, 
	#navbar-main ul#menu-primary a {display:inline-block;width: 100%;height: auto !important;}

	/* Get Navbar to overlay instead of pushing down */

	.navbar-default .navbar-collapse {
	position: absolute;
	width: 100%;
	}

	#navbar-main .navbar-collapse ul#menu-primary {
		margin:0em;
		padding: 1em 0em;
		background-color: $body-bg;
	}
	
	#stickyFill {display:none;height: 0px;}

	#navbar-main.affix ul#menu-primary li, 
	#navbar-main.affix ul#menu-primary a
	{
		height: auto !important;
	}

}


/* Set Height of Navbar Based on Full Logo Height + Margins */
#navbar-main ul#menu-primary li, 
#navbar-main ul#menu-primary a {
	height: 80px;
}


@media only screen and (min-width : $screen-sm-min) {

#navbar-main.navbar-header {float:left;clear:none;display:inline;width:auto;text-align:center;}

#navbar-main.inline-left .navbar-header {float:left;clear:none;display:inline;width:auto;text-align:center;}
#navbar-main.inline-right .navbar-header {float:right;clear:none;display:inline;width:auto;text-align:center;}
#navbar-main.above-center .navbar-header {float:right;clear:both;display:inline;width:100%;text-align:center;}
#navbar-main.above-left .navbar-header {float:right;clear:both;display:inline;width:100%;text-align:left;}
#navbar-main.above-right .navbar-header {float:right;clear:both;display:inline;width:100%;text-align:right;}

#navbar-main.above-center,
#navbar-main.above-left,
#navbar-main.above-right
{height:auto;}

#navbar-main.above-center .navbar-brand,
#navbar-main.above-left .navbar-brand,
#navbar-main.above-right .navbar-brand
{float:none;display:inline-block;}

#navbar-main.above-center #menu-primary, 
#navbar-main.above-left #menu-primary, 
#navbar-main.above-right #menu-primary
{float:none;display:table;width: 100%;text-align:center;height:auto;}

#navbar-main.above-center #menu-primary li, 
#navbar-main.above-left #menu-primary li, 
#navbar-main.above-right #menu-primary li
{float:none;display:table-cell; text-align: center; width: auto;height:auto;}

#navbar-main.above-center #menu-primary li a, 
#navbar-main.above-left #menu-primary li a, 
#navbar-main.above-right #menu-primary li a
{float:none;display:inline-block;height: auto;}

#navbar-main #menu-primary.button-float a,
#navbar-main #menu-primary.button-text a {position:relative;top: 1.5em;height:auto;}
#navbar-main #menu-primary.button-text a {background:transparent !important;}

#navbar-main.affix #menu-primary.button-text a {top:0em;}


/* For Navbar Option 2 */
#navbar-main.transparent.static-nav,
#navbar-main.transparent.affix-top
{
	background-color: transparent;
	border: none;
}
#navbar-main.transparent.static-nav #menu-primary > li > a,
#navbar-main.transparent.affix-top #menu-primary > li > a
{position:relative;top: 1.5em;height:auto !important;}
#navbar-main.transparent.static-nav #menu-primary > li > a,
#navbar-main.transparent.affix-top #menu-primary > li > a
{color: $brand-primary !important;background:fade-out(white,.15) !important;}

#navbar-main.transparent.static-nav #menu-primary > li,
#navbar-main.transparent.affix-top #menu-primary > li
{margin-left:1em;margin-right:.5em;}


}




/* Make Navigation Toggle on Desktop Hover */

/*
@media only screen and (min-width : $screen-sm-min) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}
*/

/* 
Also needs following JS:

	$('.dropdown-toggle').click(function() {
		var responsiveCheck = $( window ).width();
		if (responsiveCheck > 768) {
			var location = $(this).attr('href');
			window.location.href = location;
			return false;
		}
	});



*/